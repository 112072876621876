import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Toolbar from "@mui/material/Toolbar";
import { withStyles } from "@mui/styles";
import { Link } from "gatsby";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import React, { useEffect, useState } from "react";
import logo from "../images/thunderstock-logo.png";

const logoStyle = {
  width: 196,
  height: 40,
};

const BtnStyle = {
  marginLeft: 16,
  width: 151,
  height: 40,
  textTransform: "none",
  fontSize: 19,
  color: "white",
  fontFamily: "League Spartan, Roboto, sans-serif, serif",
};

const LinkStyle = {
  textDecoration: "none",
  color: "white",
};

const ActiveLinkStyle = {
  textDecoration: "none",
  color: "#FF9800",
  fontWeight: 800,
};

const styles = {
  paper: {
    background: "rgba(0,0,0,0.74) !important",
  },
};

const TopNavBar = (props) => {
  const breakpoints = useBreakpoint();
  const url = typeof window !== "undefined" ? window.location.href : "";
  const [activeRoute, setActiveRoute] = useState("");
  const [menuState, setMenuState] = React.useState(false);

  const handleClickToContact = () => {
    window.scrollTo(0, document.body.scrollHeight > 0 ? document.body.scrollHeight : document.documentElement.scrollHeight );
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setMenuState(open);
  };

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          height: "100%",
        }}
      >
        <ListItem button style={{ marginTop: 16, fontSize: 20 }}>
          <Link
            to="/"
            style={activeRoute == "" ? ActiveLinkStyle : LinkStyle}
          >
           Home
          </Link>
        </ListItem>
        <ListItem button style={{ marginTop: 16, fontSize: 20 }}>
          <Link
            to="/modules-page"
            style={activeRoute == "modules" ? ActiveLinkStyle : LinkStyle}
          >
            Modules
          </Link>
        </ListItem>
        <ListItem button style={{ marginTop: 16, fontSize: 20 }}>
          <Link
            to="/forecasts"
            style={activeRoute == "forecasts" ? ActiveLinkStyle : LinkStyle}
          >
            Forecasts
          </Link>
        </ListItem>
        <ListItem button style={{ marginTop: 16, fontSize: 20 }}>
          <Link
            to="/cases"
            style={activeRoute == "cases" ? ActiveLinkStyle : LinkStyle}
          >
            Cases
          </Link>
        </ListItem>
        <ListItem
          button
          onClick={() => handleClickToContact()}
          style={{ marginTop: 16, fontSize: 20 }}
        >
          <Link
            style={{ textDecoration: "none", color: "white" }}
            onClick={() => {
              handleClickToContact();
            }}
          >
            Contact
          </Link>
        </ListItem>
        {/*<ListItem button style={{ marginTop: 16, fontSize: 20 }}>*/}
        {/*  <Link*/}
        {/*      to="https://blog.thunderstock.nl/"*/}
        {/*      target={'_blank'}*/}
        {/*      style={activeRoute == "cases" ? ActiveLinkStyle : LinkStyle}*/}
        {/*  >*/}
        {/*    Blog*/}
        {/*  </Link>*/}
        {/*</ListItem>*/}
        <ListItem button style={{ marginTop: 16, fontSize: 20 }}>
          <Link
            to="https://app.thunderstock.nl"
            style={{ textDecoration: "none", color: "white" }}
            onClick={() => {
              window.location.href = "https://app.thunderstock.nl";
            }}>
            Login
          </Link>
        </ListItem>
      </List>
    </Box>
  );

  useEffect(() => {
    if (url.includes("modules")) {
      setActiveRoute("modules");
    } else if (url.includes("forecasts")) {
      setActiveRoute("forecasts");
    } else if (url.includes("cases")) {
      setActiveRoute("cases");
    } else {
      setActiveRoute("");
    }
  }, [url]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="absolute"
        color="inherit"
        style={{
          backgroundColor: "transparent",
          borderColor: "transparent !important",
          boxShadow: "none",
          textAlign: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Toolbar
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ padding: "16px" }}>
            <Link to="/">
              <img src={logo} alt="logo" style={logoStyle} />
            </Link>
          </div>

          {breakpoints.sm ? (
            <div>
              <Button onClick={toggleDrawer(true)}>
                <MenuIcon style={{ color: "white", fontSize: 35 }} />
              </Button>
              <SwipeableDrawer
                anchor={"right"}
                classes={{ paper: props.classes.paper }}
                open={menuState}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
              >
                {list()}
              </SwipeableDrawer>
            </div>
          ) : (
            <div>
              <Button style={BtnStyle} variant="text">
                <Link
                  to="/modules-page"
                  style={activeRoute == "modules" ? ActiveLinkStyle : LinkStyle}
                >
                  Modules
                </Link>
              </Button>
              <Button style={BtnStyle} variant="text">
                <Link
                  to="/forecasts"
                  style={
                    activeRoute == "forecasts" ? ActiveLinkStyle : LinkStyle
                  }
                >
                  Forecasts
                </Link>
              </Button>
              <Button variant="text" style={BtnStyle}>
                <Link
                  to="/cases"
                  style={activeRoute == "cases" ? ActiveLinkStyle : LinkStyle}
                >
                  Cases
                </Link>
              </Button>
              <Button
                variant="text"
                style={BtnStyle}
                onClick={() => handleClickToContact()}
              >
                Contact
              </Button>
              {/*<Button variant="text" style={BtnStyle}>*/}
              {/*  <Link*/}
              {/*      to="https://blog.thunderstock.nl/"*/}
              {/*      target={'_blank'}*/}
              {/*      style={activeRoute == "blog" ? ActiveLinkStyle : LinkStyle}*/}
              {/*  >*/}
              {/*    Blog*/}
              {/*  </Link>*/}
              {/*</Button>*/}
              <Button
                variant="outlined"
                style={{ ...BtnStyle, borderColor: "#FF9800" }}
                onClick={() => {
                  window.location.href = "https://app.thunderstock.nl";
                }}
              >
                <Link style={{ textDecoration: "none", color: "white" }}>
                  Login
                </Link>
              </Button>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default withStyles(styles)(TopNavBar);
