import { useBreakpoint } from "gatsby-plugin-breakpoints";
import React, { useRef } from "react";
import { animated, useSpring } from "react-spring";
import Footer from "../components/Footer";
import TopNavBar from "../components/TopNavBar";
import scrollArrow from "../images/icons/scroll-arrow.svg";
import modulesHeadImg from "../images/modules-page-header.jpg";
import modulesBlocksImg from "../images/modules-blocks.png";
import modulesBG from "../images/modules-background.jpg";
import iconAllocation from "../images/icons/icon-allocation.svg";
import strategyImage from "../images/strategy_image.png";
import chartTransfers from "../images/transfers-chart.png";
import stockImage from "../images/replenishment-chart.png";
import iconReplenishment from "../images/icons/icon-replenishment.svg";
import iconTransfers from "../images/icons/icon-transfers.svg";
import iconChannelManagement from "../images/icons/icon-channel.svg";
import buyImage from "../images/reorder-chart.png";
import iconReorder from "../images/icons/icon-reorder.svg";
import pricingImage from "../images/pricing_image.png";
import Helmet from "react-helmet";
import favicon from "../images/icons/favicon.png";

const Modules = () => {
  const breakpoints = useBreakpoint();

  const scrollBtnRef = useRef();

  const handleBackClick = () => {
    scrollBtnRef.current.scrollIntoView({ behavior: "smooth" });
  };

  // Animations
  const fadeInSlideUp = useSpring({
    to: { opacity: 1, y: 0 },
    from: { opacity: 0, y: 120 },
    delay: 800,
  });

  // Styles

  const ModulesPageStyles = {
    color: "white",
    height: "100%",
    fontFamily: "League Spartan, Roboto, sans-serif, serif",
    zIndex: 1,
    padding: 0,
    margin: 0,
    letterSpacing: 0.66,
    position: "relative",
    overflow: "hidden",
  };

  const ModulesHead = {
    backgroundImage: `url(${modulesHeadImg})`,
    backgroundSize: "cover",
    height: breakpoints.sm ? 650 : 725,
    backgroundRepeat: "repeat-y",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 16,
    zIndex: -1,
  };

  const ScrollArrow = {
    position: "absolute",
    top: breakpoints.sm ? "55%" : "65%",
    left: "0",
    right: "0",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    zIndex: 100,
  };

  const ModulesExplanation = {
    height: breakpoints.sm ? 650 : 700,
    display: "flex",
    flexDirection: breakpoints.sm ? "column" : "row",
    padding: breakpoints.sm ? "286px 26px 26px 26px" : "104px 0 0 0",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
    marginTop: -226,
    position: "relative",
    background:
      "linear-gradient(0deg, rgba(0,0,0,1) 5%, rgba(0,0,0,1) 75%, rgba(0,0,0,0) 90%)",
  };

  const SectionTitle = {
    color: "#FF9800",
    fontSize: 15,
  };

  const SectionExplanationTitle = {
    fontSize: 30,
    lineHeight: 1.3,
    fontWeight: 600,
    letterSpacing: 0.78,
    width: breakpoints.sm ? "100%" : 438,
  };

  const SectionText = {
    fontWeight: 500,
    letterSpacing: 0,
    width: breakpoints.sm ? "100%" : 438,
  };

  const TheModules = {
    height: breakpoints.sm ? "100%" : 2400,
    backgroundImage: `url(${modulesBG})`,
    backgroundSize: "cover",
    backgroundRepeat: "repeat-y",
    display: "flex",
    justifyContent: "center",
    transform: "skewY(-6deg)",
    zIndex: 1,
    marginBottom: -100,
  };

  const TheModulesInner = {
    display: "grid",
    gridTemplate: breakpoints.sm ? "auto / 1fr" : "auto / 1fr 1fr",
    gridGap: breakpoints.sm ? 16 : 56,
    justifyContent: "center",
    maxWidth: breakpoints.sm ? "100%" : 986,
    padding: breakpoints.sm ? "16px 26px 26px 26px" : "204px 0 26px 0px",
    transform: "skewY(6deg)",
    paddingBottom: breakpoints.sm ? 180 : 140,
  };

  const ModulesGridTextWrapper = {
    fontWeight: 600,
    color: "#011413",
    width: breakpoints.sm ? "100%" : 438,
    marginTop: 26,
    fontSize: 16,
  };

  const ModuleImage = {
    width: breakpoints.sm ? "100%" : 513,
    height: breakpoints.sm ? "100%" : 295,
    boxShadow: "4px 12px 16px rgba(0,0,0,0.04), 4px 2px 4px rgba(0,0,0,0.04)",
    borderRadius: 8,
  };

  const ModulesInfo = {
    position: "absolute",
    top: 300,
    right: 0,
    left: 0,
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    height: 600,
    padding: breakpoints.sm ? 16 : 0,
  };

  return (
    <div style={ModulesPageStyles}>
      <Helmet>
        <title>Thunderstock</title>
        <link rel="canonical" href="http://thunderstock.nl" />
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
      </Helmet>
      <TopNavBar />

      <div style={ModulesHead}></div>
      <div style={ModulesInfo}>
        <h2
          style={{
            width: breakpoints.sm ? "100%" : 400,
            fontSize: 34,
            textAlign: "center",
          }}
        >
          Optimize your merchandise processes
        </h2>
        <div style={ScrollArrow}>
          <animated.div style={fadeInSlideUp}>
            <img src={scrollArrow} onClick={handleBackClick} alt="arrow" />
          </animated.div>
        </div>
      </div>

      <div style={ModulesExplanation} ref={scrollBtnRef}>
        <div>
          <h3 style={SectionTitle}>Our modules</h3>

          <h2 style={SectionExplanationTitle}>
            Each of our stock modules focus on optimizing different stages of
            the product life cycle
          </h2>

          <p style={SectionText}>
            The modules aim at improving margin and stock efficiency by reducing
            missed sales and avoiding over-stocks. This allows you to be more
            agile and responsive to trends.
          </p>
        </div>

        <div>
          <img
            src={modulesBlocksImg}
            alt="module blocks"
            style={{
              width: breakpoints.sm ? 252 : 352,
              marginLeft: breakpoints.sm ? 0 : 285,
              paddingTop: breakpoints.sm ? 26 : 0,
            }}
          />
        </div>
      </div>
      <div style={TheModules}>
        <div style={TheModulesInner}>
          <div style={ModulesGridTextWrapper}>
            <div style={{ display: "flex" }}>
              <img
                src={iconAllocation}
                style={{ width: 35 }}
                alt="allocation icon"
              />
              <h2 style={{ fontSize: 30, marginLeft: 32 }}>Strategy</h2>
            </div>

            <p>
              The Strategy module oversees all pre-season decision-making,
              aligning various aspects of your budget: financial, sales, and
              product. We offer the following functionality:
              <ul>
                <li>Smart, collaborative budgets</li>
                <li>OTB & intake planning</li>
                <li>WSSI (in-season monitoring)</li>
              </ul>
            </p>
          </div>

          <div style={{ gridColumn: breakpoints.sm ? "1/2" : "2/3" }}>
            <img
              src={strategyImage}
              alt="allocation chart"
              style={ModuleImage}
            />
          </div>

          <div
            style={{
              ...ModulesGridTextWrapper,
              gridColumn: breakpoints.sm ? "1/2" : "2/3",
            }}
          >
            <div style={{ display: "flex" }}>
              <img src={iconReplenishment} style={{ width: 35 }} alt="icon" />
              <h2 style={{ fontSize: 30, marginLeft: 32 }}>Buy</h2>
            </div>

            <p>
              The Buy module helps you build the best possible collection and
              create optimal (re)orders for all your existing and new articles.
              We offer the following functionality:
              <ul>
                <li>Collection planning</li>
                <li>(Re)ordering</li>
                <li>Pack optimization</li>
              </ul>
            </p>
          </div>

          <div
            style={{
              gridColumn: "1/2",
              gridRow: breakpoints.sm ? "auto" : "2/3",
            }}
          >
            <img src={buyImage} alt="chart" style={ModuleImage} />
          </div>

          <div style={ModulesGridTextWrapper}>
            <div style={{ display: "flex" }}>
              <img
                src={iconChannelManagement}
                style={{ width: 35 }}
                alt="allocation icon"
              />
              <h2 style={{ fontSize: 30, marginLeft: 32 }}>Stock</h2>
            </div>

            <p>
              The Stock module optimizes all stock movements throughout the
              product lifecycle, with a strong focus on automation, service
              levels and advanced stock insights. We offer the following
              functionality:
              <ul>
                <li>Allocation & replenishment</li>
                <li>Transfers & consolidation</li>
                <li>Channel planning</li>
              </ul>
            </p>
          </div>

          <div style={{ gridColumn: breakpoints.sm ? "1/2" : "2/3" }}>
            <img
              src={stockImage}
              alt="management chart"
              style={ModuleImage}
            />
          </div>

          <div style={ModulesGridTextWrapper}>
            <div style={{ display: "flex", gridColumn: "2/3" }}>
              <img
                src={iconTransfers}
                style={{ width: 35 }}
                alt="allocation icon"
              />
              <h2 style={{ fontSize: 30, marginLeft: 32 }}>Logistics</h2>
            </div>

            <p>
              The Logistics module helps you optimize all logistical flows,
              maximizing profit and improving service levels within logistical
              capacity. We offer the following functionality:
              <ul>
                <li>Load estimation</li>
                <li>Load balancing</li>
                <li>Route optimization</li>
              </ul>
            </p>
          </div>

          <div
            style={{
              gridColumn: "1/2",
              gridRow: breakpoints.sm ? "auto" : "4/5",
            }}
          >
            <img
              src={chartTransfers}
              alt="transfers chart"
              style={ModuleImage}
            />
          </div>

          <div style={ModulesGridTextWrapper}>
            <div style={{ display: "flex" }}>
              <img
                src={iconReorder}
                style={{ width: 35 }}
                alt="allocation icon"
              />
              <h2 style={{ fontSize: 30, marginLeft: 32 }}>Pricing</h2>
            </div>

            <p>
              The Pricing module aims to reduce overstock positions at the end
              of the season while maximizing total lifetime margin. We offer the
              following functionality:
              <ul>
                <li>Smart markdown budgets</li>
                <li>Markdown proposals</li>
                <li>Promotion analysis</li>
              </ul>
            </p>
          </div>

          <div style={{ gridColumn: breakpoints.sm ? "1/2" : "2/3" }}>
            <img
              src={pricingImage}
              alt="allocation chart"
              style={ModuleImage}
            />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Modules;
