import "@fontsource/league-spartan";
import React  from "react";
import "@fontsource/league-spartan";
import Contact from "./Contact.jsx";

const Footer = () => {
    return (
        <div>
            <Contact/>
        </div>
    )
};

export default Footer;
