import React from 'react';
import contactBG from '../images/contact-bg.jpg';
import { Box, TextField, Typography, Container, Grid } from '@mui/material';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import {LoadingButton} from "@mui/lab";

const Contact = () => {
    const [nameError, setNameError] = React.useState(false);
    const [emailError, setEmailError] = React.useState(false);
    const [sent, setSent] = React.useState(false);
    const [loading, setLoading] = React.useState(false);


    const onSubmit = async (event) => {
        event.preventDefault();
        setNameError(false);
        setEmailError(false)
        const formData = new FormData(event.target);

        if (!formData.get("name")) {
            setNameError(true);
        }
        if (!formData.get("email")) {
            setEmailError(true);
        }

        if (nameError || emailError) {
            return;
        }

        formData.append("access_key", "ed7a2cbb-10ba-4890-a5ba-9ac6788f1a38");
        formData.append("subject", "New contact submission");
        formData.append("from_name", "Submission: " + formData.get("name"));

        setLoading(true);
        const response = await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            body: formData
        });

        const data = await response.json();

        if (data.success) {
            event.target.reset();
        } else {
            console.error("Error", data);
        }
        setLoading(false);
        setSent(true);
    };
    const breakpoints = useBreakpoint();

    return (
        <div>
        <Box
            sx={{
                backgroundImage: `url(${contactBG})`,
                color: '#fff',
                minHeight: 400,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                padding: breakpoints.sm ? '46px 16px 16px 16px' : '114px 64px 64px 64px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
            }}
        >
            <Container maxWidth="lg" style={{maxWidth: breakpoints.sm ? "100%" : 986, width: 986, paddingLeft: 30}}>
                <Grid container spacing={12} alignItems={"flex-start"} style={{ display: "flex", justifyContent: "space-between" }}>
                    <Grid item xs={12} md={6}>
                        <Box
                            sx={{
                                textAlign: breakpoints.sm ? 'center' : 'left',
                                mb: breakpoints.md ? 0 : 4,
                            }}
                        >
                            <Typography variant="h3" gutterBottom sx={{ color: 'rgb(255, 152, 0)' }}>
                                Meet us at
                            </Typography>
                            <Typography variant="body1">Diemermere 1, 1112TA</Typography>
                            <Typography variant="body1">Diemen, The Netherlands</Typography>
                            <Typography variant="body1" mt={2}>
                                CoC: 76541223
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Typography variant="h3" align="center" gutterBottom sx={{ color: 'rgb(255, 152, 0)' }}>
                            Ready for more?
                        </Typography>

                        <Box
                            component="form"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: 2,
                                mt: 2,
                            }}
                            onSubmit={onSubmit}
                        >
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            type={"text"}
                                            name={"name"}
                                            fullWidth
                                            label="Your name"
                                            variant="standard"
                                            InputLabelProps={{ style: { color: '#ffffff' } }}
                                            sx={{
                                                '& .MuiInput-underline:before': { borderBottomColor: 'white' },
                                                '& .MuiInput-underline:after': { borderBottomColor: 'white' },
                                                '&:hover .MuiInput-underline:before': { borderBottomColor: 'white!important' },
                                                '&:hover .MuiInput-underline:after': { borderBottomColor: 'white!important' },
                                                input: { color: '#ffffff' },
                                            }}
                                            error={nameError}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            type={"email"}
                                            name={"email"}
                                            label="Email address"
                                            variant="standard"
                                            InputLabelProps={{ style: { color: '#ffffff' } }}
                                            sx={{
                                                '& .MuiInput-underline:before': { borderBottomColor: 'white' },
                                                '& .MuiInput-underline:after': { borderBottomColor: 'white' },
                                                '&:hover .MuiInput-underline:before': { borderBottomColor: 'white!important' },
                                                '&:hover .MuiInput-underline:after': { borderBottomColor: 'white!important' },
                                                input: { color: '#ffffff' },
                                            }}
                                            error={emailError}
                                        />
                                    </Grid>
                                </Grid>
                                <TextField
                                    fullWidth
                                    rows={4}
                                    multiline
                                    name={"message"}
                                    label="Tell me more about..."
                                    variant="standard"
                                    InputLabelProps={{ style: { color: '#ffffff' } }}
                                    sx={{
                                        '& .MuiInput-input': { color: '#ffffff' },
                                        '& .MuiInput-underline:before': { borderBottomColor: 'white' },
                                        '& .MuiInput-underline:after': { borderBottomColor: 'white' },
                                        '&:hover .MuiInput-underline:before': { borderBottomColor: 'white!important' },
                                        '&:hover .MuiInput-underline:after': { borderBottomColor: 'white!important' },
                                        input: { color: '#ffffff' },
                                    }}
                                />
                                <LoadingButton
                                    type={"submit"}
                                    variant="outlined"
                                    color="inherit"
                                    sx={{
                                        paddingX: 3,
                                        paddingY: 1,
                                        width: 151,
                                        height: 40,
                                        marginTop: 2,
                                        textTransform: 'none',
                                        borderColor: '#fff',
                                        '&:hover': {
                                            borderColor: '#ccc',
                                        },
                                        "& .MuiLoadingButton-loadingIndicator": {
                                            color: 'white!important',
                                        },
                                    }}
                                    loadingPosition={"center"}
                                    loading={loading}
                                >
                                    I AM READY
                                </LoadingButton>
                            {sent ? (
                                <Typography variant="h6" sx={{ color: 'white', mt: 2 }}>
                                   Thank you! We will get back to you soon!
                                </Typography>
                            ) : (
                                <></>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
            <div style={{ display: "flex", paddingInline: "20px", justifyContent: "space-between" }}>
                <div>
                    <h4 style={{ color: "#5B5B5B", fontWeight: 300 }}>
                        © 2024 Thunderstock
                    </h4>
                </div>
                <div>
                    <h4 style={{ fontWeight: 300 }}>Privacy statement</h4>
                </div>
            </div>
        </div>
    );
};

export default Contact;
